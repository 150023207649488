const {disableScroll, enableScroll} = require('./scroll')

class Modal {
    constructor(config) {
        this.el = document.querySelector(config.el);
        if (!this.el) return;
        this.overlay = this.el.querySelector('.modal__overlay');
        this.closeEl = this.el.querySelector('.modal__close');
        if (this.overlay) {
            this.overlay.addEventListener('click', () => {
                enableScroll()
                this.el.classList.remove('modal__open')
            })
        }
        if (this.closeEl) {
            this.closeEl.addEventListener('click', (e) => {
                enableScroll()
                this.el.classList.remove('modal__open')
            })
        }

    }

    open = () => {
        this.el.classList.add('modal__open')
        disableScroll()
    }
    close = () => {
        enableScroll()
        this.el.classList.remove('modal__open')

    }

}

module.exports = {
    Modal
}