const {Swiper, Autoplay, Pagination, Navigation, Grid} = require('swiper');


function networksVerticalSlider(selector, dalay = 3000) {
    if (!document.querySelector(selector)) return;
    const sliderContainer = document.querySelector(selector);
    const items = sliderContainer.innerHTML;
    sliderContainer.innerHTML += items;
    const [firstItems, lastItems] = sliderContainer.querySelectorAll('.networks-slider__items');
    firstItems.querySelectorAll('.networks-slider__item').forEach(item => {
        setInterval(() => {
            item.classList.add('networks-slider-first-item')
            setTimeout(() => {
                item.classList.remove('networks-slider-first-item')
            }, dalay - 500)
        }, dalay)
    })
    lastItems.querySelectorAll('.networks-slider__item').forEach(item => {
        setInterval(() => {
            item.classList.add('networks-slider-last-item')
            setTimeout(() => {
                item.classList.remove('networks-slider-last-item')
            }, dalay - 500)
        }, dalay)
    })

}

function networksHorizontalSlider(selector, paginationSelector) {
    if (!document.querySelector(selector)) return;
    return new Swiper(selector, {
        slidesPerView: 2,
        slidesPerGroup: 2,
        loop: true,
        modules: [Pagination, Autoplay],
        pagination: {
            el: paginationSelector,
            clickable: true,
        },
        autoplay: {
            delay: 2500
        },

    });
}

function teamSlider(selector, paginationSelector) {
    if (!document.querySelector(selector)) return;
    const teamSliderEl = new Swiper(selector, {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        modules: [Pagination, Autoplay],
        speed: 0,
        pagination: {
            el: paginationSelector,
            clickable: true,
        },
        autoplay: {
            delay: 20000
        },
    });
    teamSliderEl.on('slideChangeTransitionStart', (e) => {
        const img = e.wrapperEl.children[e.activeIndex - 1].children[1];
        startAnimation(img, 'image__scale-start', 500)
    })
    teamSliderEl.on('slideChangeTransitionEnd', (e) => {
        const [
            img,
            desc,
            name,
            prof,
        ] = [
            e.wrapperEl.children[e.activeIndex].children[0],
            e.wrapperEl.children[e.activeIndex].children[1],
            e.wrapperEl.children[e.activeIndex].children[2],
            e.wrapperEl.children[e.activeIndex].children[3],
        ]
        startAnimation(name, 'fide', 500)
        startAnimation(desc, 'bubbling-text', 500)
        startAnimation(prof, 'fide', 500)
        startAnimation(img, 'image__scale', 500)
    })
    return teamSliderEl;
}

function blockcheinStackMobileSlider(selector, paginationSelector) {
    if (!document.querySelector(selector)) return;

    const slider = new Swiper(selector, {
        slidesPerView: 2,
        slidesPerGroup: 2,
        grid: {
            rows: 2
        },
        modules: [Autoplay, Pagination, Grid],
        autoplay: {
            delay: 3000,
        },
        pagination: {
            el: paginationSelector,
            clickable: true,
        },
    });
}

function managementSlider(selector) {
    const sliderContainer = document.querySelector(selector);
    if (!sliderContainer) return;
    const managmentTeamSlider = new Swiper(selector, {
        slidesPerView: 'auto',
        loop: true,
        modules: [Autoplay],
        speed: 5000,
        queueEndCallbacks: true,

        autoplay: {
            delay: 0,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,

        },

    })

    return managmentTeamSlider;
}

function simpleSlider(selector, paginationSelector, nextEl=null, prevEl=null) {
    const sliderContainer = document.querySelector(selector);
    if (!sliderContainer) return;
    const managmentTeamSlider = new Swiper(selector, {
        slidesPerView: 'auto',
        loop: true,
        slidesPerGroup: 1,
        spaceBetween: 32,
        modules: [Autoplay, Pagination, Navigation],
        navigation: {
            nextEl: nextEl,
            prevEl: prevEl,
        },
        pagination: {
            el: paginationSelector,
            clickable: true,
        },
        autoplay: {
            delay: 3000
        },
        breakpoints: {
            768: {
                autoplay: false
            }
        }


    })
    return managmentTeamSlider
}


function startAnimation(el, className, ms = 1000, delay) {
    el.style = `
        animation-duration: ${ms}ms;
        animation-delay: ${delay}ms;
    `
    el.classList.add(className);
    setTimeout(() => {
        el.classList.remove(className);
    }, ms + 500)
}

function bannerSlider() {
    new Swiper('.banner-slider', {
        slidesPerView: 'auto',
        loop: true,
        modules: [Autoplay, Navigation, Pagination],
        navigation: {
            nextEl: ".banner-slider__arrows_next",
            prevEl: ".banner-slider__arrows_prev",
        },
        pagination: {
            el: '.banner-slider__pagination',
            clickable: true,
        },
        autoplay: {
            delay: 3000
        },
    })
}

function aboutCompanySlider(selector, paginationSelector) {
    return new Swiper(selector, {
        slidesPerView: 'auto',
        loop: true,
        modules: [Autoplay, Pagination],
        pagination: {
            el: paginationSelector,
            clickable: true,
        },
        autoplay: {
            delay: 3000
        },
    })
}

function developmentServiceSlider(selector) {
    if (!document.querySelector(selector)) return;
    return new Swiper(selector, {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 30,
        modules: [Autoplay, Pagination],
        pagination: {
            el: '.development-services__pagination',
            clickable: true,
        },
        autoplay: {
            delay: 3000
        },
    })
}

function technologyStackSlider(selector) {
    if (!document.querySelector(selector)) return;
    return new Swiper(selector, {
        slidesPerView: 'auto',
        loop: true,
        spaceBetween: 16,
        modules: [Autoplay],
        autoplay: {
            delay: 3000
        },
    })
}

function metaverseDevelopmentSlider(selector) {
    if (!document.querySelector(selector)) return;
    return new Swiper(selector, {
        slidesPerView: 'auto',
        loop: true,
        spaceBetween: 30,
        modules: [Autoplay, Pagination],
        pagination: {
            el: '.metaverse-development__pagination',
            clickable: true,
        },
        autoplay: {
            delay: 3000
        },
    })
}


module.exports = {
    networksVerticalSlider,
    networksHorizontalSlider,
    teamSlider,
    blockcheinStackMobileSlider,
    managementSlider,
    simpleSlider,
    bannerSlider,
    aboutCompanySlider,
    developmentServiceSlider,
    technologyStackSlider,
    metaverseDevelopmentSlider

}
