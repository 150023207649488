function scrollEntrypoint() {
    window.addEventListener('scroll', (e) => {
        let scroll = window.scrollY;
        if (window.innerWidth >= 768) {
            ScrollEfects(scroll)
            closeHeaderSubMenu()
        }
        autoChangeLinkColors(scroll)
        headerFixed(scroll)
    });

}

function openHeaderSubMenu(selector) {
    let items = document.querySelectorAll(selector)
    items.forEach(item => {
        item.addEventListener('mouseenter', e => {
            let submenu = e.target.querySelector('.header__nav-children')
            if (submenu) {
                submenu.classList.add('header__nav-children-open');
            }
        })
        item.addEventListener('mouseleave', () => {
            closeHeaderSubMenu()
        })
    })
}

function closeHeaderSubMenu() {
    let items = document.querySelectorAll('.header__nav-children')
    if (!items) return;
    items.forEach(item => {
        item.classList.remove('header__nav-children-open')
    })
}

function headerFixed(scroll) {
    const header = document.querySelector('header');
    const main = document.querySelector('.main');
    const className = 'header__fixed';
    const activeClassName = 'header__active'
    if (scroll >= header.clientHeight) {
        main.style = `padding-top:${header.clientHeight}px;`
        header.classList.add(className)
        header.classList.add(activeClassName)
    } else {
        if (!header.classList.contains('burger-clicked')) {
            main.style = `unset;`
            header.classList.remove(className)
            header.classList.remove(activeClassName)
        }
    }
}

function ScrollEfects(scroll) {

    scrollStartEnd('.specialization-info', '.specialization-info-wrapper', scroll, 200)
    scrollStartEnd('.image__block', '.image__block-img', scroll, 0, 156)
    scrollStartEnd('.block-page__content_left', '.block-page__content_left-content', scroll, 0, 160)
    scrollStartEnd('.management__team_info', '.management__team_info-wrapper', scroll, 85, 160)
}

function scrollStartEnd(parent, children, scroll, pb = 0, top = 116) {
    parents = document.querySelectorAll(parent)
    childrens = document.querySelectorAll(children)
    if (!parent || !children) return;
    let parentWidth;
    let childrenHeight
    let parentLeft;
    let parentOffsetTop
    let parentScrollHeight;
    let position = 'static';
    parents.forEach(parent => {
        parentWidth = parent.clientWidth;
        parentLeft = parent.offsetLeft;
        parentOffsetTop = parent.offsetTop;
        parentScrollHeight = parent.scrollHeight;
    })
    childrens.forEach(children => {
        childrenHeight = children.clientHeight;
    })

    if (scroll + top > parentOffsetTop) position = 'fixed';
    if (scroll > parentOffsetTop + parentScrollHeight - childrenHeight - pb - top) {
        top = (parentOffsetTop + parentScrollHeight - childrenHeight) - scroll - pb;
    }
    parents.forEach((parent, i) => {
        scrollElement(parent, childrens[i], parentWidth, parentLeft, position, top)
    })

}

function scrollElement(parent, children, parentWidth, parentLeft, position, top) {
    parent.style = `
            min-width: ${parentWidth}px;
            width: ${parentWidth}px;
        `
    children.style = `
            max-width: ${parentWidth}px;
            position: ${position};
            top: ${top}px;
            left: ${parentLeft}px;
            z-index: 99999999;
        `
}

function getLinksAndTitles() {
    const links = document.querySelectorAll('.scroll-link');
    const data = []
    links.forEach(link => {
        const id = link.href.split('#')[1];
        const title = document.querySelector(`#${id}`);
        data.push({link, title})
    })
    return data;
}

function eventOnScrollLink() {
    const data = getLinksAndTitles()
    if (!data) return;
    data.forEach(items => {
        items.link.addEventListener('click', (e) => {
            e.preventDefault();
            scrollTo({
                top: items.title.offsetTop - 150
            })
        })
    })
}

function autoChangeLinkColors(scroll) {
    const data = getLinksAndTitles()
    if (!data) return;
    data.forEach(items => {
        if (scroll >= items.title.offsetTop - 200 && scroll <= items.title.offsetTop - 150) {
            changeActiveLink(data, items.link)
        } else if (scroll < data[0].title.offsetTop - 150) {
            changeActiveLink(data, data[0].link)
        }
    })
}

function changeActiveLink(data, link) {
    data.forEach(item => item.link.classList.remove('block-page__content_menu-link-active'))
    link.classList.add('block-page__content_menu-link-active')
}

let keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () {
            supportsPassive = true;
        }
    }));
} catch (e) {
}

let wheelOpt = supportsPassive ? {passive: false} : false;
let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}


module.exports = {
    scrollEntrypoint,
    openHeaderSubMenu,
    eventOnScrollLink,
    enableScroll,
    disableScroll

}
