(() => {
    const CountryList = require('country-list-with-dial-code-and-flag');
    const {
        networksVerticalSlider,
        networksHorizontalSlider,
        teamSlider,
        blockcheinStackMobileSlider,
        managementSlider,
        simpleSlider,
        bannerSlider,
        aboutCompanySlider,
        developmentServiceSlider,
        technologyStackSlider,
        metaverseDevelopmentSlider
    } = require('./sliders')
    const {
        scrollEntrypoint,
        openHeaderSubMenu,
        eventOnScrollLink,
        enableScroll,
    } = require('./scroll')
    const {Modal} = require('./modal')


    /*============================ Scrolls ====================================*/
    scrollEntrypoint();
    eventOnScrollLink()
    /*============================ Scrolls ====================================*/

    /*============================ Popups ====================================*/
    const modal = new Modal({
        el: '.modal'
    })
    new Modal({
        el: '.modal__form_countries'
    })

    function contactUs(selector) {
        const contactUsButtonst = document.querySelectorAll(selector);
        if (!contactUsButtonst) return;
        contactUsButtonst.forEach(btn => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                modal.open()
            })
        })
    }

    contactUs('.contact-us')
    /*============================ Popups ====================================*/

    /*============================ Header ====================================*/
    function changeHeader() {
        const header = document.querySelector(window.innerWidth <= 1023 ? '.header' : '.header-mobile')
        header.remove()
    }

    const toggleMenu = (btnSelector, menuSelector) => {
        const header = document.querySelector('.header-mobile')
        btnSelector = header.querySelector(btnSelector)
        if (!btnSelector) return;
        menuSelector = header.querySelector(menuSelector)
        btnSelector.addEventListener('click', (e) => {
            btnSelector.classList.toggle('header-mobile__burger-close');
            menuSelector.classList.toggle('header-mobile__menu-active');
            header.classList.toggle('burger-clicked');
            if (!header.classList.contains('header__fixed')) {
                header.classList.toggle('header__active');

            }

        })
    }
    toggleMenu('.header-mobile__burger', '.header-mobile__menu')
    changeHeader()
    openHeaderSubMenu('.header__nav-li ')
    /*============================ Header ====================================*/

    /*============================ Sliders ====================================*/
    bannerSlider()
    teamSlider('.team__slider', '.team__swiper-pagination')
    managementSlider('.management__team-slider')

    simpleSlider('.card-section__slider', '.card-section__pagination', ".card-section__slider-navigation .next", ".card-section__slider-navigation .prev")
    simpleSlider('.game-types__slider', '.game-types__pagination', '.game-types__navigation .next', '.game-types__navigation .prev')

    if (window.innerWidth <= 768) {
        blockcheinStackMobileSlider('.blockchain__stack-slider', '.blockchain__stack-swiper-pagination')
        networksHorizontalSlider('.networks-slider-class', '.networks-slider__pagination')
        aboutCompanySlider('.about-company__cards_slider', '.about-company__cards_slider-pagination')
        developmentServiceSlider('.development-services__content')
        metaverseDevelopmentSlider('.metaverse-development__slider')
    } else {
        networksVerticalSlider('.networks-slider-class')
        technologyStackSlider('.technology-stack__slider')
    }
    /*============================ Sliders ====================================*/


    /*============================ Drop Down  ====================================*/
    function dropDown() {
        const dropdownContainer = document.querySelectorAll('.drop-down')
        if (!dropdownContainer) return;
        const contents = document.querySelectorAll('.drop-down-content')
        contents.forEach(content => {
            content.classList.add('drop-down-hide')
        })
        dropdownContainer.forEach(dropdownBlock => {
            const button = dropdownBlock.querySelector('.drop-down-button')
            button.addEventListener('click', (e) => {
                e.preventDefault()
                e.target.classList.toggle('dropdown__link_icon-active')
                e.target.parentElement.classList.toggle('drop-down-active-link')
                dropdownBlock.childNodes.forEach(children => {
                    if (children.nodeName !== '#text') {
                        if (children.classList.contains('drop-down-content')) {
                            children.classList.toggle('drop-down-hide')
                        }
                    }

                })
            })
        })
    }

    dropDown()

    const dropdownFooterMenu = (selector) => {
        const items = document.querySelectorAll(selector)
        if (!items) return;
        items.forEach(item => {
            const button = item.querySelector('.mobile__dropdown-arrow')
            button.addEventListener('click', (e) => {
                e.preventDefault()
                let menu = item.querySelector('.footer__navigation_items')
                button.classList.toggle('mobile__dropdown-arrow-active')
                menu.classList.toggle('footer__navigation_items-active')

            })
        })
    }
    dropdownFooterMenu('.footer__navigation')

    function dropdown(selectors) {
        selectors = document.querySelectorAll(selectors)

        selectors.forEach(selector => {
            const item = selector.children[0];
            const [title, content] = item.children;
            title.addEventListener('click', () => item.classList.toggle('dropdown-item-active'))

        })
    }

    dropdown('.dropdown')

    /*============================ Drop Down  ====================================*/

    /*============================ View all items  ====================================*/
    const viewAll = (btn, block, display) => {
        let isViewAll = false
        btn = document.querySelector(btn)
        if (!btn) return;
        block = document.querySelector(block)

        btn.addEventListener('click', (e) => {
            isViewAll = !isViewAll
            if (!isViewAll) {
                e.target.innerHTML = 'View all'
                block.style.display = 'none'
            } else {
                e.target.innerHTML = 'View less'
                block.style.display = display
            }
        })
    }
    viewAll('.specialization-view-all', '.specialization-items-bottom', 'flex')
    /*============================ View all items  ====================================*/

    /*============================ Blockchain stack item hover effect  ====================================*/
    const blockchainStackHover = (selector) => {
        const items = document.querySelectorAll(selector);
        if (!items) return;
        items.forEach(item => {
            item.addEventListener('mouseenter', (e) => {
                const icon = e.target.querySelector('.blockchain__stack-icon')
                icon.classList.remove('blockchain__stack-icon-dark')
            })
            item.addEventListener('mouseleave', (e) => {
                const icon = e.target.querySelector('.blockchain__stack-icon')
                icon.classList.add('blockchain__stack-icon-dark')
            })
        })
    }

    blockchainStackHover('.blockchain__stack-item')
    /*============================ Blockchain stack item hover effect  ====================================*/


    /*============================ Disabled buttons  ====================================*/
    function disabled(className) {
        document.querySelectorAll(className).forEach(item => {
            item.addEventListener('click', e => {
                e.preventDefault();
                e.stopPropagation();
            })
        })
    }

    disabled('.disabled')
    /*============================ Disabled buttons  ====================================*/

    /*============================ Send mail  ====================================*/
    function sendMail() {
        const form = document.querySelectorAll('.mail__form')
        if (!form) return;

        form.forEach(item => {
            item.addEventListener('submit', (e) => {
                e.preventDefault();
                if (localStorage.getItem('valid')) return;
                const data = new FormData(e.target)
                const modal = document.querySelector('.modal')
                const notification = document.querySelector('.notification')
                data.append('url', window.location.href)
                function renderMessage() {
                    modal.classList.remove('modal__open')
                    if (notification) {
                        notification.classList.add('notification-show')
                        const notificationClose = document.querySelector('.notification')
                        notificationClose.addEventListener('click', () => {
                            notification.classList.remove('notification-show')
                        })
                        setTimeout(() => {
                            notification.classList.remove('notification-show')
                        }, 4000)
                    }
                }

                fetch('/contact/', {
                    method: 'POST',
                    body: data
                }).then(j => j.json())
                    .then(data => {
                        renderMessage()
                        const inputs = item.querySelectorAll('input')
                        const textarea = item.querySelector('textarea')
                        textarea.value = ''
                        inputs.forEach(input => {
                            input.value = ''
                        })

                    })
                    .finally(() => {
                        enableScroll()
                    })
                    .catch(e => {
                    })
            })
        })
    }

    sendMail()
    /*============================ Send mail  ====================================*/


    /*============================ Cookies popup  ====================================*/
    function cookies(selector) {
        const cookiesPopup = document.querySelector(selector)
        const cookiesAccept = cookiesPopup.querySelector('.cookies__button')
        const cookiesClose = cookiesPopup.querySelector('.cookies__close')

        let cookie_consent = getCookie("user_cookie_consent");
        if (cookie_consent !== "") {
            cookiesPopup.style.display = "none";
        } else {
            cookiesPopup.style.display = "flex";
        }

        // Create cookie
        function setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        // Delete cookie
        function deleteCookie(cname) {
            const d = new Date();
            d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=;" + expires + ";path=/";
        }

        // Read cookie
        function getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        // Set cookie consent
        cookiesAccept.addEventListener('click', (e) => {
            deleteCookie('user_cookie_consent');
            setCookie('user_cookie_consent', 1, 30);
            cookiesPopup.style.display = "none";
        })
        cookiesClose.addEventListener('click', () => {
            cookiesPopup.style.display = "none";
        })

    }

    cookies('.cookies')
    /*============================ Cookies popup  ====================================*/

    /*============================ Validator ====================================*/
    const emailRegexp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const phoneRegexp = /^\+?\d+$/;

    function validator(el, regexp, message) {
        const block = document.querySelector(el);
        if (!block) return;
        const messageTag = document.createElement('span');
        const input = block.querySelector('input');
        messageTag.classList.add('validate-message');
        messageTag.textContent = message;
        input.addEventListener('input', (e) => {
            if (!e.target.value.match(regexp) && e.target.value) {
                block.appendChild(messageTag)
                block.classList.add('no-valid')
                localStorage.setItem('valid', 'no')
            } else {
                messageTag.remove()
                block.classList.remove('no-valid')
                localStorage.removeItem('valid')
            }

        })

    }

    validator('.modal__form_item-email', emailRegexp, 'Invalid email')
    validator('.modal__form_item-phone', phoneRegexp, 'Invalid phone')
    validator('.contacts-module__content_form-input-phone', phoneRegexp, 'Invalid phone')
    /*============================ Validator ====================================*/

    function hoverLogo(){
        const logo = document.querySelector('.header__logo img');
        const src = logo.src;
        logo.addEventListener('mousemove', () => {
            let newSrc = src.split('/')
            newSrc[newSrc.length - 1] = 'Definme-red.svg';
            newSrc = newSrc.join('/')
            logo.src = newSrc;
        })
        logo.addEventListener('mouseleave', () => {
            logo.src = src;
        })
    }
    hoverLogo()
})()